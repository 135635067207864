import React, { Component } from "react";
import "./controls.scss";

import SearchButton from "./SearchButton";
import FilterAllButton from "./FilterAllButton";
import TypeButton from "./TypeButton";
import AboutMapButton from "./AboutMapButton";
import DynamicControlsContent from "./DynamicControlsContent";
import DateFilter from "../sharedComponents/DateFilter";
import CloseButton from "../sharedComponents/CloseButton";

class ControlsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minimizeFilter: false
    };
  }
  render() {
    const classNames = this.props.controlButton.hasControl
      ? "control-buttons active"
      : "control-buttons";
    let numberOfAllDataPoints = 0;

    for (const key in this.props.geoJSONDataStatistics) {
      numberOfAllDataPoints =
        numberOfAllDataPoints + this.props.geoJSONDataStatistics[key].all;
    }

    let containerClasses =  this.state.minimizeFilter && window.innerWidth <= 1000 ?  'controls-container controls--hidden' : 'controls-container'

    return (
      <div className={containerClasses}>
        <DateFilter
          setDateFilter={this.props.setDateFilter}
          filterType={"all"}
          dateValues={this.props.dateFilter["all"]}
        ></DateFilter>
        <div className={classNames}>
          <CloseButton classNames="filter-close--mobile" closeButton={() => this.setState({minimizeFilter: !this.state.minimizeFilter})} />
          <SearchButton onClick={this.props.onClick}></SearchButton>
          <FilterAllButton
            onClick={this.props.onClick}
            numberOfDataPoints={numberOfAllDataPoints}
          ></FilterAllButton>
          <AboutMapButton onClick={this.props.onClick} />

          {Object.keys(this.props.geoJSONData).map(
            function (key) {
              return (
                <TypeButton
                  key={"control-button--" + this.props.geoJSONData[key].name}
                  fullName={this.props.geoJSONData[key].fullName}
                  shortName={this.props.geoJSONData[key].name}
                  onClick={this.props.onClick}
                  numberOfDataPoints={this.props.geoJSONDataStatistics[key].all}
                ></TypeButton>
              );
            }.bind(this)
          )}
        </div>
          <DynamicControlsContent
            type={this.props.controlButton.type}
            {...this.props}
          ></DynamicControlsContent>
      </div>
    );
  }
}

export default ControlsView;
