import React from "react";
import { useLocation } from "react-router-dom";
import "normalize.css";
import "./scss/App.scss";
import MapboxAPI from "./MapAPI/MapboxAPI";
import GeoJSONData from "./MapAPI/GeoJSONData";
import GeoJSONFilter from "./inc/GeoJSONFilter";
import logo_geoengineering from "./Images/logo_etc_group_geoengineering.png"; // gives image path
import logo_hbs from "./Images/logo_hbs_geoengineering.png"; // gives image path

//import Components
import DescriptionView from "./Description/DescriptionView";
import ControlsView from "./Controls/ControlsView";
import WelcomeWindow from "./WelcomeWindow/WelcomeWindow";
import { PageViews } from '@piwikpro/react-piwik-pro';
import Piwik from "./Analytics/Piwik";

class App extends React.Component {
  map = {};
  mapAPI = new MapboxAPI();
  geoJSONData = new GeoJSONData();
  geoJSONFilter = new GeoJSONFilter();

  constructor(props) {
    super(props);

    this.mapAPI.mapIsLoaded = this.mapIsLoaded;
    this.geoJSONData.addLayerToMap = this.addLayerToMap;
    this.geoJSONData.initialSetupDone = this.initialSetupDone;

    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 5;

    const allFilterButton = new Map();
    allFilterButton
      .set("project_status-completed", true)
      .set("project_status-cancelled", true)
      .set("project_status-planned", true)
      .set("project_status-ongoing", true);

    this.state = {
      initialSetupDone: false,
      geoJSONData: {},
      geoJSONDataStatistics: {},
      descriptionProperties: false,
      controlButton: { hasControl: false },
      allFilterButton: allFilterButton,
      defaultFilter: {},
      savedFilter: {},
      clearedLayers: {},
      showWelcomeWindow: true,
      analyticsConsentGiven: false,
      showAnalyticsConsentWindow: true,
      searchLoading: false,
      searchTerm: "",
      searchResults: [],
      config: this.mapAPI.geoJSONDataLayers,
      route: decodeURI(props.route.pathname),
      dateFilter: {
        ggr: [startYear, currentYear],
        srm: [startYear, currentYear],
        wm: [startYear, currentYear],
        other: [startYear, currentYear],
        all: [startYear, currentYear],
      },
    };

    this.tempState = {};
    this.analytics = new Piwik(this);

  }

  initializeFilter(json) {
    const savedFilter = { ...this.state.savedFilter };
    savedFilter[json.name] = {};
    savedFilter[json.name] = this.geoJSONFilter.initializeFilter(
      json.name,
      json.features
    );

    const defaultFilter = JSON.parse(JSON.stringify(savedFilter));
    this.setState({ savedFilter, defaultFilter });
  }

  componentDidMount() {
    this.map = this.mapAPI.initializeMap(this.mapContainer);
    this.mapAPI.setDescription = this.setDescription.bind(this);
  }

  mapIsLoaded = () => {
    this.geoJSONData.fetchGeoJSONData();
  };

  addLayerToMap = (json) => {
    this.mapAPI.addLayer(json);
    const geoJSONData = { ...this.state.geoJSONData };
    geoJSONData[json.name] = json;
    geoJSONData[json.name].numberOfDataPoints = json.features.length;

    this.initializeFilter(json);

    this.analyzeGeoJSONData(json);
    this.setState({ geoJSONData });
  };

  initialSetupDone = () => {
    if (this.state.route) {
      const element = this.getElementByRoute(this.state.route);

      if (element) {
        this.setDescription(element.properties);
        const options = {
          center: element.geometry.coordinates,
          zoom: 9,
          bearing: 0,
          essential: true,
        };
        this.map.flyTo(options);
      }
    }

    const savedFilter = this.state.savedFilter;

    for (const key in savedFilter) {
      this.applyFilter(savedFilter[key][0]);
    }

    this.setState({ initialSetupDone: true });
    this.analytics.init()
  };

  getSourceData = (id) => {
    const sourceData = { ...this.state.geoJSONData[id] };
    return sourceData;
  };

  analyzeGeoJSONData = (data) => {
    const id = data.name;

    const relevantFeatures = ["type1", "type2", "project_status"];

    const countedProperties = {};
    countedProperties.all = data.numberOfDataPoints;

    data.features.forEach((feature) => {
      relevantFeatures.forEach((relevantFeature) => {
        let dataFeatures = feature.properties[relevantFeature]
        if (!Array.isArray(dataFeatures)) dataFeatures = [dataFeatures]

        dataFeatures.forEach(dataFeature => {
          if (countedProperties[dataFeature]) {
            countedProperties[dataFeature]++;
          } else {
            countedProperties[dataFeature] = 1;
          }

        })
      });
    });

    const geoJSONDataStatistics = { ...this.state.geoJSONDataStatistics };
    geoJSONDataStatistics[id] = countedProperties;

    this.tempState["geoJSONDataStatistics"] =
      this.tempState["geoJSONDataStatistics"] || {};
    this.tempState["geoJSONDataStatistics"][id] = countedProperties;

    this.setState({
      geoJSONDataStatistics: this.tempState["geoJSONDataStatistics"],
    });
  };

  setDescription(elementProperties) {
    let descriptionProperties = false;

    if (elementProperties) {
      descriptionProperties = this.state.geoJSONData[elementProperties.layerID].features.find((data) => data.properties.id === elementProperties.id)
      const options = {
        center: descriptionProperties.geometry.coordinates,
        bearing: 0,
        essential: true,
      };
      this.map.flyTo(options);
      descriptionProperties = descriptionProperties.properties
    }

    this.setState({ descriptionProperties });

  }

  setSourceData = (sourceID, sourceData) => {
    const source = this.map.getSource(this.mapAPI.getSourceID(sourceID));
    source.setData(sourceData);
  };

  applyFilter = (filterObject, filter = false, applyFilter = true) => {
    /*const filterObjectExample = {
      filterID: "ggr-type1-artificial-upwelling",
      filterType: "ggr",
      checked: true, //filter will only be applied, if it is checked
      parentType: "Type ",
      property: "GeoJSONProperty that should be filtered",
      value: "value we want to have filtered",
    };*/
    const { filterID, filterType, checked } = filterObject;

    const savedFilter = !filter ? { ...this.state.savedFilter } : filter;
    savedFilter[filterType] = savedFilter[filterType] || [
      this.geoJSONFilter.getEmptyFilter(filterType),
    ];
    savedFilter[filterType].forEach((filter) => {
      if (filter.filterID === filterID) {
        filter.checked = checked;
      }
    });

    // if we have a custom filter, we don't want to change anything on the general state of our filters
    if (!filter) {
      this.setState({ savedFilter });
    }

    const sourceData = this.geoJSONFilter.filterSourceData(
      this.getSourceData(filterType),
      savedFilter[filterType]
    );
    if (applyFilter) {
      this.setSourceData(filterType, sourceData);
      this.analyzeGeoJSONData(sourceData);
    }
  };

  setDateFilter = (start, end) => {
    const clearedLayers = { ...this.state.clearedLayers };
    const dateFilter = { ...this.state.dateFilter };
    for (const filterType in this.state.geoJSONData) {
      const applyFilter = !clearedLayers[filterType] ? true : false;
      const startFilter = { ...start };
      this.resetDateFilter(filterType);
      startFilter.filterType = filterType;
      startFilter.filterID = startFilter.filterID.replace("all", filterType);
      this.applyFilter(startFilter, false, applyFilter);

      const endFilter = { ...end };

      endFilter.filterType = filterType;
      endFilter.filterID = endFilter.filterID.replace("all", filterType);
      this.applyFilter(endFilter, false, applyFilter);

      dateFilter[filterType] = [start.value, end.value];
      this.setState({ dateFilter });
    }
    dateFilter["all"] = [start.value, end.value];
    this.setState({ dateFilter });
  };

  resetDateFilter = (filterType) => {
    const savedFilter = { ...this.state.savedFilter };

    savedFilter[filterType].forEach((filter) => {
      if (filter.parentType === "Date") {
        filter.checked = false;
      }
    });

    this.setState({ savedFilter });
  };

  showConsentWindow = () => {
    this.analytics.showConsentWindow()
  }
  maybeRenderWelcomeWindows = () => {
    const userWillShowWelcomeMessage =
      localStorage.getItem("showWelcomeMessage") === null;

    return <div className="welcome-window--container">
      {this.state.showWelcomeWindow && userWillShowWelcomeMessage && <WelcomeWindow main={this}></WelcomeWindow>}
    </div>;
  };

  setControlsContent = (controlButton) => {
    this.setState({ controlButton });
  };

  getElementByRoute(route) {
    let elementByRoute = false;

    for (const key in this.state.geoJSONData) {
      for (let index = 0; index < this.state.geoJSONData[key].features.length; index++) {
        const element = this.state.geoJSONData[key].features[index]
        if (
          element.properties.shareUrl &&
          element.properties.shareUrl === route
        ) {
          elementByRoute = element;
        }
      }
    }

    if (!elementByRoute) {
      const parsedRoute = route.split("/").filter((el) => el.length !== 0);
      if (parsedRoute.length === 0) {
        return false;
      }

      for (const key in this.state.geoJSONData) {

        for (let index = 0; index < this.state.geoJSONData[key].features.length; index++) {
          const element = this.state.geoJSONData[key].features[index]
          const parsedShareUrl = element.properties.shareUrl
            .split("/")
            .filter((el) => el.length !== 0);

          if (parsedShareUrl[1] === parsedRoute[1]) {
            elementByRoute = element;
          }

        }
      }
    }

    return elementByRoute;
  }

  render() {
    return (
      <div className="App">
        <div className="logo logo-container">
          <a
            className="logo-image logo-geoengineering"
            href="https://www.etcgroup.org/"
            target="_blank"
            rel={'noreferrer'}
          >
            {" "}
            <img
              src={logo_geoengineering}
              alt="logo etc-group geoengineering"
            />{" "}
          </a>

          <a
            className="logo-image logo-hbs"
            href="https://www.boell.de/"
            target="_blank"
            rel={'noreferrer'}
          >
            {" "}
            <img src={logo_hbs} alt="logo heinrich-boell-stiftung" />
          </a>
        </div>

        {this.state.descriptionProperties && (
          <DescriptionView
            key="description"
            main={this}
            descriptionProperties={this.state.descriptionProperties}
          ></DescriptionView>
        )}
        {this.maybeRenderWelcomeWindows()}
        {!this.state.initialSetupDone && (
          <div className="initialloader-container">
            <i className="fa fa-cog fa-spin initialloader show" />
          </div>
        )}
        <ControlsView
          main={this}
          onClick={this.setControlsContent}
          {...this.state}
          setDateFilter={this.setDateFilter}
        ></ControlsView>

        <div ref={(el) => (this.mapContainer = el)} className="mapContainer" />
      </div>
    );
  }
}

const AppExport = (props) => <App {...props} route={useLocation()} />
export default AppExport;
