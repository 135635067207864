import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Main from "./Main.js";
import * as serviceWorker from "./serviceWorker";
import PiwikPro from '@piwikpro/react-piwik-pro';


const containerID = 'c92e48c7-0c47-44d9-a696-8abca178e44c';
const containerUrl = 'https://boell.containers.piwik.pro';
PiwikPro.initialize(containerID, containerUrl);

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
