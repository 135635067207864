import {PageViews} from "@piwikpro/react-piwik-pro";

export default class Piwik {
  app = {};

  constructor(app) {
    this.app = app;
  }

  init(){
    window.ppms.cm.api('getComplianceSettings', this.trackPageViewIfUserConsentGiven.bind(this), ()=> {});
  }
  trackPageViewIfUserConsentGiven(data){
    this.app.setState({analyticsConsentGiven: false})
    if(data.consents.analytics.status === 1) this.trackPageView()
  }

  trackPageView() {
    this.app.setState({analyticsConsentGiven: true})
    PageViews.trackPageView('Geoengineering Map');
  }

  showConsentWindow() {
    window.ppms.cm.api('openConsentForm', () => {}, () => {});
  }
}
