export default class WelcomeWindowController {
  app = {};

  constructor(app) {
    this.app = app;
    this.consent = false;
  }

  dontShowWelcomeWindowAgain = (checked) => {
    if (checked) {
      localStorage.setItem("showWelcomeMessage", false);
    } else {
      localStorage.removeItem("showWelcomeMessage");
    }
  };

  closeWelcomeWindow = () => {
    this.app.setState({ showWelcomeWindow: false });
  };
}
